<script>
import Loader from "vue-element-loading";
import api from "../api";
import NoImage from "../assets/no-image.jpg";
import Message from "ant-design-vue/lib/message";
import { mapGetters } from "vuex";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      product: {},
      loading: false,
      currentMain: "",
      noImage: NoImage,
    };
  },
  methods: {
    load() {
      const pId = this.$route.params.productId;
      this.loading = true;
      api
        .singleProduct(pId)
        .then((response) => {
          this.product = response.data.items;
          const productArray = this.product;
          productArray.forEach((element) => {
            this.currentMain = element.main_image;
          });
          this.loading = false;
        })
        .catch((error) => {
          Message.error("error");
          console.error(error);
        });
    },
    checkUserPoints(productPoints) {
      if (productPoints > this.userPoints) {
        return false;
      } else return true;
    },
    cartLimitReached(productPoints) {
      const combinedTotal = productPoints + this.cartPoints;
      if (combinedTotal >= this.userPoints) {
        return true;
      } else return false;
    },
    addToCart() {
      const pId = this.$route.params.productId;
      console.debug(pId);
      console.log(`The single product:  ${this.product[0]}`);
      const points = this.product[0].points;
      const quantity = 1;
      if (this.loggedIn) {
        if (this.cartLimitReached(points)) {
          Message.warning("Seems your cart is full!! Checkout to complete :)");
          return;
        }
        const canAdd = this.checkUserPoints(points);
        if (!canAdd) {
          Message.warning("Not enough points!");
          return;
        }
        this.$store
          .dispatch("Cart/addToCart", {
            product: this.product[0],
            quantity: quantity,
          })
          .then(() => {
            Message.loading("Adding Item to cart...", 1).then(() => {
              Message.success("Item added to your cart! :)");
            });
          })
          .catch((e) => {
            if (e.response.status === 404) {
              Message.info(e.response.data.message);
            } else if (e.response.status == 500) {
              Message.info("Oops bumped into an error");
            } else if (e.response.status == 401) {
              Message.info("User not logged in.");
              localStorage.removeItem("next");
              this.$router.push({ name: "Login" });
            }
          });
      } else {
        Message.info("Login required first");

        // must unset the stored path
        localStorage.removeItem("next");

        this.$router.push({
          name: "Login",
        });
      }
    },
    setMain(url) {
      this.currentMain = url;
    },
  },
  computed: {
    ...mapGetters({
      cartPoints: "Cart/cartTotalPoints",
      userPoints: "Auth/userPoints",
    }),
    loggedIn() {
      return this.$store.state.Auth.token;
    },
  },
  created() {
    this.load();
    this.$store.commit("Nav/SET_PAGE", "single-p");
  },
};
</script>
<template>
  <div id="single-product">
    <div class="sp-container">
      <div class="sp-row" v-for="key in product" :key="key.id">
        <div class="sp-left">
          <loader :active="loading" spinner="ring" />
          <div class="img-area">
            <div class="img-section__main">
              <img :src="currentMain" alt class="img-section__main--image" />
            </div>
            <div class="img-section__secondary">
              <img
                v-for="image in key.secondary_images"
                :key="image.id"
                :src="image.url"
                @click="setMain(image.url)"
                class="img-section__secondary--image"
              />
            </div>
          </div>
        </div>
        <div class="sp-right text-area">
          <h1 class="title">{{ key.title }}</h1>
          <p class="points">
            <a class="pill-success--large">
              <i class="fas fa-star icon-left"></i>
              Points: {{ key.points }}
            </a>
            <span class="condition"
              >Item Condition: {{ key.condition.name }}</span
            >
          </p>
          <p class="desc">{{ key.description }}</p>
          <div class="qty">Available Stock: {{ key.quantity }}</div>
          <a @click.prevent="addToCart" class="blue-button-sm add-cart-btn">
            <i class="fas fa-plus icon"></i>Add to Cart
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../assets/styles/_mixins.scss";
@import "../assets/styles/_components.scss";
@import "../assets/styles/_utilities.scss";
nav {
  position: relative;
  top: 0rem;
  // margin-top: -1.2rem;
  background: rgba(0, 0, 0, 1);
}
#single-product {
  position: relative;
  top: 5.2rem;
  // margin-top: 5.2rem;
}
.add-cart-btn {
  margin-bottom: 7rem;
}
.text-area {
  margin-left: -3.3rem;
}
.sp-container {
  // margin-left: 10.8rem;
  // margin-right: 10.8rem;
  // padding-left: 5.5rem;
  // padding-right: 5.5rem;
}
.sp-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // padding: 0 1.3rem;
  @include respond(tab_p) {
  }
  @include respond(tab_l) {
  }
  @include respond(phone) {
    flex-direction: column;
  }
}
.sp-column {
  flex: 100%;
  max-width: 50%;
  padding: 0 0.2rem;
}
.sp-left {
  margin-right: 14.9rem;
}
.fl {
  font-size: 2.3rem;
}
.img-area {
  display: flex;
  flex-direction: column;

  @include respond(tab_p) {
  }
  @include respond(tab_l) {
  }
  @include respond(phone) {
    padding: 5rem;
  }
  cursor: pointer;
  // max-: 45.0rem;
  .img-section__main {
    padding: 3rem;
    &--image {
      max-width: 45rem;
      max-height: 39rem;
      @include respond(tab_p) {
      }
      @include respond(tab_l) {
      }
      @include respond(phone) {
        max-width: 21rem;
      }
    }
  }
  .img-section__secondary {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &--image {
      cursor: pointer;
      padding: 1rem;
      max-width: 8.5rem;
      max-height: 8.5rem;
    }
  }
}

.title {
  margin: 3.2rem 0;
  font-family: "Anton", sans-serif;
  max-width: 38rem;
  font-size: 3.4rem;
  text-transform: uppercase;
  @include respond(tab_p) {
  }
  @include respond(tab_l) {
  }
  @include respond(phone) {
    font-size: 3.2rem;
  }
}
.points {
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 2.6rem;
  color: var(--color-grey-dark-1);
  @include respond(phone) {
    flex-direction: column;
    width: 15rem;
  }
}
.desc {
  max-width: 52rem;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.8rem;
  color: var(--color-grey-dark-1);
  @include respond(tab_p) {
  }
  @include respond(tab_l) {
  }
  @include respond(phone) {
    width: 27rem;
  }
}
.qty {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.8rem;
  color: var(--color-grey-dark-2);
  margin-bottom: 3.6rem;
}
.condition {
  font-family: var(--source-sans);
  font-size: 1.4rem;
  color: var(--color-grey-dark-2);
  margin-left: 1rem;
}
.icon {
  margin-right: 0.8rem;
}
</style>
